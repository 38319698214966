import { atom } from "nanostores";
import { useStore } from "@nanostores/react";
const $store = atom(undefined as unknown as string);
const is_dev = import.meta.env.DEV;
const dev_domain = "https://192.168.1.31:3000";

export const hooks = {
  useQRLink: () => useStore($store),
};

export const actions = {
  setCodeSubRoute(value: string) {
    const domain = is_dev ? dev_domain : window.origin;
    const code = domain + value;
    console.log("qr code :: ", code);
    $store.set(code);
  },
};
